import useCopyAccountInfo from '@/hooks/useCopyAccountInfo'
import { delay } from '@/utils/delay'
import { urlCheck } from '@/hooks/UrlCheck';
// function openApp() {
//   var schemeUrl = `intent://open#Intent;scheme=panda_9;end`
//   var fallbackUrl = "https://play.google.com/store/apps/details?id=com.example.app";

//   // 尝试通过 URL Scheme 打开应用
//   var timeout;
//   var start = Date.now();
//   var iframe = document.createElement("iframe");
//   iframe.style.display = "none";
//   iframe.src = schemeUrl;

//   document.body.appendChild(iframe);

//   // 如果超过2秒还没跳转，意味着应用可能未安装
//   timeout = setTimeout(function () {
//     if (Date.now() - start < 2000) {
//       window.location.href = fallbackUrl;  // 跳转到应用商店
//     }
//   }, 1500);

//   window.onblur = function () {
//     clearTimeout(timeout);  // 如果应用打开，取消跳转
//   };
// }
export default async () => {
  const channelStore = useChannelStore();
  const apkUrl = channelStore.promotionInfo?.installUrl;
  const reg = /.+_(\d+)\.apk$/
  const id = apkUrl?.match(reg)?.[1]
  let intentURL = location.href.replace(`${location.protocol}//`, '');
  useCopyAccountInfo()
  //intent://${intentURL}#Intent;
  // window.open(`panda_247://open`, '_blank')
  location.href = `intent://open#Intent;scheme=panda_247;end`
  // window.location = `intent://open#Intent;scheme=panda_${id};end`
  // openApp() com.zdr3qa.ddi6l6qhb
  await delay(1200)

  location.href = urlCheck(apkUrl);
}
